import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextInput } from "@lbc-toolkit/textinput";
import { IconButton } from "@mui/material";
import {
    MRT_Row,
    MRT_Table,
    useMaterialReactTable,
    type MRT_ColumnDef
} from 'material-react-table';
import { useEffect, useState } from "react";
import { ColumnInformation, TablePropertyValue } from "../../../services/composer";
import { DataFieldLayout } from "./dataField.layout";

type DataFieldTableProps = {
    containerRef: React.RefObject<HTMLDivElement>;
    setEditMode: (value: boolean) => void;

    label?: string;
    data: TablePropertyValue;
    
    isDataLoading?: boolean;
    
    onEdit?: (newValue: TablePropertyValue) => void;
    isExpandable?: boolean;
};

export const DataFieldTable = ({ containerRef, setEditMode, label, data: tableData, isDataLoading, onEdit, isExpandable }: DataFieldTableProps) => {
    const [editingCell, setEditingCell] = useState<{ rowIndex: number, columnId: string } | null>(null);
    const [tableRows, setTableRows] = useState(() => {
        return Array.from({ length: Math.max(...tableData.values?.map(colInfo => colInfo.values?.length ?? 0) ?? []) }).map((_, rowIndex) => {
            const row: Record<string, string | undefined> = {};
            tableData.values?.forEach((colInfo) => {
                row[colInfo.columnName ?? `col${rowIndex}`] = colInfo.values?.[rowIndex];
            });
            return row;
        });
    });

    useEffect(() => {
        setTableRows(() => {
            return Array.from({ length: Math.max(...tableData.values?.map(colInfo => colInfo.values?.length ?? 0) ?? []) }).map((_, rowIndex) => {
                const row: Record<string, string | undefined> = {};
                tableData.values?.forEach((colInfo) => {
                    row[colInfo.columnName ?? `col${rowIndex}`] = colInfo.values?.[rowIndex];
                });
                return row;
            });
        });
    }, [tableData])

    const handleEdit = (newValue: string, rowIndex: number, columnId: string) => {
        const updatedData = {
            discriminator: "TablePropertyValue",
            values: tableData.values ? [...tableData.values] : null,
        } as TablePropertyValue;

        if (!updatedData.values) return;
        const column = updatedData.values.find(colInfo => colInfo.columnName === columnId);
        if (column && column.values) {
            column.values[rowIndex] = newValue;
        }
        onEdit?.(updatedData);
    };

    const handleAddRow = () => {
        const updatedData = {
            discriminator: "TablePropertyValue",
            values: tableData.values ? [...tableData.values] : null,
        } as TablePropertyValue;

        if (!updatedData.values) return;
        updatedData.values.forEach(colInfo => {
            colInfo.values?.push("");
        });
        onEdit?.(updatedData);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (editingCell && !containerRef.current?.contains(event.target as Node)) {
                setEditingCell(null);
                setEditMode(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editingCell, containerRef, setEditMode]);

    const columns: MRT_ColumnDef<ColumnInformation>[] = tableData.values?.map((colInfo: ColumnInformation) => {
        return {
            accessorKey: colInfo.columnName ?? undefined,
            header: colInfo.columnName ?? "",
            enableHiding: false,
            Cell: ({ cell }) => {
                const isEditing = editingCell?.rowIndex === cell.row.index && editingCell?.columnId === cell.column.id;
                return onEdit && isEditing ? (
                    <TextInput
                        type="text"
                        width="100%"
                        value={cell.getValue() ?? ""}
                        onChange={(newValue: string) => {
                            handleEdit(newValue, cell.row.index, cell.column.id);
                        }}
                    />
                ) : (
                    <div
                        onClick={() => setEditingCell({ rowIndex: cell.row.index, columnId: cell.column.id })}
                        style={{ padding: '4px', height: '40px', width: '100%', alignContent: 'center', cursor: onEdit ? 'pointer' : 'default' }}
                    >
                        {cell.getValue() as string}
                    </div>
                );
            },
        };
    }) ?? [];

    onEdit && columns.push({
        accessorKey: 'actions',
        header: '',
        enableHiding: false,
        size: 50,
        Cell: ({ cell }) => {
            return (
                <IconButton
                    color='error'
                    style={{
                        width: '100%',
                        height: '40px',
                    }}
                    onClick={() => {
                        const updatedRows = [...tableRows];
                        updatedRows.splice(cell.row.index, 1);
                        setTableRows(updatedRows);

                        const updatedData = {
                            discriminator: "TablePropertyValue",
                            values: tableData.values ? [...tableData.values] : null,
                        } as TablePropertyValue;

                        if (!updatedData.values) return;
                        updatedData.values.forEach(colInfo => {
                            colInfo.values = updatedRows.map(row => row[colInfo.columnName ?? ""] ?? "");
                        });
                        onEdit?.(updatedData);
                    }}
                >
                    <FontAwesomeIcon color='#eb4034' className='fa-xs' icon={faTrash} />
                </IconButton>
            );
        },
    });

    const table = useMaterialReactTable({
        columns,
        data: tableRows,
        enableKeyboardShortcuts: false,
        enableColumnActions: false,
		positionActionsColumn: 'last',
        enableColumnFilters: false,
        enablePagination: false,
        enableSorting: false,
        enableRowOrdering: !!onEdit,
        columnResizeMode: 'onEnd',
        renderEmptyRowsFallback: () => <></>,
        muiRowDragHandleProps: ({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                tableRows.splice(
                  (hoveredRow as MRT_Row<ColumnInformation>).index,
                  0,
                  tableRows.splice(draggingRow.index, 1)[0],
                );
                setTableRows([...tableRows]);

                const updatedData = {
                    discriminator: "TablePropertyValue",
                    values: tableData.values ? [...tableData.values] : null,
                } as TablePropertyValue;

                if (!updatedData.values) return;
                updatedData.values.forEach(colInfo => {
                    colInfo.values = tableRows.map(row => row[colInfo.columnName ?? ""] ?? "");
                });
                onEdit?.(updatedData);
              }
            },
          }),
        muiTableBodyRowProps: { hover: false },
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid rgba(81, 81, 81, .5)',
                borderBottom: '2px solid rgba(81, 81, 81, .5)',
                fontWeight: 'bold',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                padding: '0px',
                height: '40px',
                borderBottom: '1px solid rgba(81, 81, 81, .5)',
                borderLeft: '1px solid rgba(81, 81, 81, .5)',
                borderRight: '1px solid rgba(81, 81, 81, .5)',
            },
        },
    });

    return (
        <DataFieldLayout
            content={
                <div className="py-2">
                    <MRT_Table table={table} />
                    { onEdit ? 
                        <div
                            onClick={handleAddRow}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: '#f6f6f6',
                                textAlign: 'center',
                                alignContent: 'center',
                                height: '40px',
                                border: '1px solid rgba(81, 81, 81, .2)',
                            }}
                        ><FontAwesomeIcon className='fa-xs' icon={faPlus} /></div>
                        :
                        null
                    }
                </div>}
            containerRef={containerRef}
            label={label}
            setEditMode={setEditMode}
            isDataLoading={isDataLoading}
            isExpandable={isExpandable}
        />
    );
};