import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, Divider, IconButton, IconButtonProps, styled, Typography } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { PropertySetDto } from '../../../../services/warehouse';

interface PropertySetProps {
    children: ReactNode;
    set: PropertySetDto;
    setIdx: number;
    dataLength: number;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
  const ExpandMore = styled((props: ExpandMoreProps) => {
      const { expand, ...other } = props;
      return <IconButton {...other} />;
    })(() => ({
      marginLeft: 'auto',
      transition: 'transform 0.3s',
      variants: [
        {
          props: ({ expand }) => !expand,
          style: {
            transform: 'rotate(0deg)',
          },
        },
        {
          props: ({ expand }) => !!expand,
          style: {
            transform: 'rotate(180deg)',
          },
        },
      ],
  }));
  

export const PropertySet: React.FC<PropertySetProps> = ({ children, set, setIdx, dataLength }) => {
    const [expanded, setExpanded] = useState(true);
    
    return (
        <React.Fragment key={set.id}>
            <Typography variant="h6">
                <>
                {set.name}
                    <ExpandMore
                        expand={expanded}
                        onClick={() => setExpanded(!expanded)}
                        >
                        <ExpandMoreIcon />
                    </ExpandMore>    
                </>
            </Typography>
            <div className="row" style={{ margin: 0 }}>
                <Collapse in={expanded} timeout='auto'>
                    <div style={{ alignContent: 'center', minHeight: '40px', padding: 0, paddingLeft: '64px' }}>
                        {children}
                    </div>
                </Collapse>
            </div>
            { setIdx !== dataLength - 1 && <Divider sx={{ backgroundColor: 'black' }} /> }
        </React.Fragment>
    );
}