import { Dropdown } from '@lbc-toolkit/dropdown';
import { DataFieldLayout } from "./dataField.layout";

type DataFieldDropdownProps = {
    containerRef: React.RefObject<HTMLDivElement>;
    editMode: boolean;
    setEditMode: (value: boolean) => void;

    label?: string;
    data: string;
    dropdownOptions?: string[];
    
    isDataLoading?: boolean;
    
    onEdit?: (newValue: string) => void;
};

export const DataFieldDropdown = ({ containerRef, editMode, setEditMode, label, data, dropdownOptions, isDataLoading, onEdit }: DataFieldDropdownProps) => {    
    return (
        <DataFieldLayout
                content={onEdit && editMode ?
                    <Dropdown
                        options={dropdownOptions?.map((x) => ({ label: x, value: x }))}
                        placeholder={label}
                        onSelect={(selectedValue: string) => onEdit?.(selectedValue)}
                        selected={data}
                    />
                    :
                    <div className="px-2">{data}</div>
                }
                containerRef={containerRef}
                label={label}
                setEditMode={setEditMode}
                isDataLoading={isDataLoading}
                enableHovering={true}
            />
    );
}