import { Divider, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { PropertySetDto } from "../../services/catalog";
import { PropertyCollectionDto, PropertyEntryDto, PropertyUsageType } from "../../services/composer";
import DataField from "../dataField";
import PropertySet from "./components/propertySet";
import usePropertyCollection from "./hooks/usePropertyCollection";

type PropertyCollectionProps = {
    data?: PropertyCollectionDto;
    onEdit?: (updatedData: PropertyCollectionDto) => void;
};

export const PropertyCollection: React.FC<PropertyCollectionProps> = ({ data, onEdit }) => {
    const { propertySetsMap, handleEdit, ungroupedEntries } = usePropertyCollection({ data, onEdit });
	const { t } = useTranslate();
    
    return (
        <>
			<Typography variant='h5'>{t('properties')}</Typography>
            {!data && <Typography>No data available!</Typography>}

            {/* Render grouped PropertySets */}
            {data?.propertySets?.map((set: PropertySetDto, setIdx: number) => (
                <PropertySet key={setIdx} dataLength={data.propertySets!.length} set={set} setIdx={setIdx}>
                    {set.id && propertySetsMap[set.id]?.map((entry: PropertyEntryDto, idx: number) => (                    
                        <React.Fragment key={idx}>
                            <DataField
                                label={entry.property?.name || 'Unnamed Property'}
                                data={entry.value!}
                                isExpandable={true}
                                onEdit={
                                    onEdit && entry.propertyUsageType === PropertyUsageType.Type
                                    ? (newValue) => handleEdit(entry, newValue)
                                    : undefined
                                }
                            />
                            { idx !== (propertySetsMap[set.id!]?.length || 0) - 1 && <Divider className="my-1" sx={{ backgroundColor: 'black' }} /> }
                        </React.Fragment>
                    ))}
                </PropertySet>
            ))}

            {/* Render properties without any PropertySet */}
            <div>
                {ungroupedEntries.map((entry: PropertyEntryDto, idx: number) => (                
                    <React.Fragment key={idx}>
                        <DataField
                            label={entry.property?.name || 'Unnamed Property'}
                            data={entry.value || 'No value'}
                            isExpandable={true}
                            onEdit={
                                onEdit && entry.propertyUsageType === PropertyUsageType.Type
                                ? (newValue) => handleEdit(entry, newValue)
                                : undefined
                            }
                        />
                        { idx !== ungroupedEntries.length - 1 && <Divider sx={{ backgroundColor: 'black' }} /> }
                    </React.Fragment>                   
                ))}
            </div>
        </>
    );
};
