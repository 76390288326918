import React, { useEffect, useRef, useState } from "react";
import { CalculatedPropertyValue, DataType, ObjectPropertyValue, SimplePropertyValue, TablePropertyValue } from "../../services/composer";
import { DataFieldDropdown } from './components/dataField.dropdown';
import { DataFieldNumber } from "./components/dataField.number";
import { DataFieldObject } from "./components/dataField.object";
import { DataFieldString } from './components/dataField.string';
import { DataFieldTable } from "./components/dataField.table";
import './style.scss';

type DataFieldProps = {
    label?: string;
    data: string | number | SimplePropertyValue | CalculatedPropertyValue | ObjectPropertyValue | TablePropertyValue;
    dropdownOptions?: string[];
    
    isDataLoading?: boolean;
    
    onEdit?: (newValue: string | number | SimplePropertyValue | CalculatedPropertyValue | ObjectPropertyValue | TablePropertyValue) => void;
    isExpandable?: boolean;
    isDecimal?: boolean;
};

export const DataField: React.FC<DataFieldProps> = ({ label, data, dropdownOptions, isDataLoading, onEdit, isExpandable, isDecimal }) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const onClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setEditMode(false);
        }
    };

    useEffect(() => {
        if (editMode) {
            document.addEventListener('mousedown', onClickOutside);
        } else {
            document.removeEventListener('mousedown', onClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, [editMode]);

    // string
    if (typeof data === 'string' && dropdownOptions === undefined) {
        return (
            <DataFieldString
                containerRef={containerRef}
                editMode={editMode}
                setEditMode={setEditMode}
                
                label={label}
                data={data}

                isDataLoading={isDataLoading}

                onEdit={onEdit}  
            />
        );
    }

    // number
    if (typeof data === 'number' && dropdownOptions === undefined) {
        return (
            <DataFieldNumber
                containerRef={containerRef}
                editMode={editMode}
                setEditMode={setEditMode}
                
                label={label}
                data={data}

                isDataLoading={isDataLoading}
                isDecimal={isDecimal}

                onEdit={onEdit}
            />
        );
    }

    // Dropdown
    if (typeof data === 'string' && dropdownOptions) {
        return (
            <DataFieldDropdown
                containerRef={containerRef}
                editMode={editMode}
                setEditMode={setEditMode}
                
                label={label}
                data={data}
                dropdownOptions={dropdownOptions}

                isDataLoading={isDataLoading}

                onEdit={onEdit}
            />
        );
    }

    // SimplePropertyValue
    if (typeof data === 'object' && data['discriminator'] === 'SimplePropertyValue') {
        
        if((data as SimplePropertyValue).dataType === DataType.String) {
            return (
                <DataFieldString
                    containerRef={containerRef}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    
                    label={label}
                    data={(data as SimplePropertyValue).value ?? ""}

                    isDataLoading={isDataLoading}

                    onEdit={
                        onEdit ?
                        (newValue: string) => onEdit?.({ discriminator: "SimplePropertyValue", dataType: DataType.String, value: newValue } as SimplePropertyValue)
                        :
                        undefined
                    }
                />
            );
        } else if((data as SimplePropertyValue).dataType === DataType.Integer) {
            return (
                <DataFieldNumber
                    containerRef={containerRef}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    
                    label={label}
                    data={parseInt((data as SimplePropertyValue).value ?? "0")}

                    isDataLoading={isDataLoading}
                    isDecimal={false}

                    onEdit={
                        onEdit ?
                        (newValue: number) => onEdit?.({ discriminator: "SimplePropertyValue", dataType: DataType.Integer, value: newValue.toString() } as SimplePropertyValue)
                        :
                        undefined
                    }
                />
            );
        } else if((data as SimplePropertyValue).dataType === DataType.Double) {
            return (
                <DataFieldNumber
                    containerRef={containerRef}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    
                    label={label}
                    data={parseFloat((data as SimplePropertyValue).value ?? "0.00")}

                    isDataLoading={isDataLoading}
                    isDecimal={true}

                    onEdit={
                        onEdit ?
                        (newValue: number) => onEdit?.({ discriminator: "SimplePropertyValue", dataType: DataType.Double, value: newValue.toString() } as SimplePropertyValue)
                        :
                        undefined
                    }
                />
            );
        } else {
            return <div>Unsupported Data Type</div>;
        }

    }

    // CalculatedPropertyValue
    if (typeof data === 'object' && data['discriminator'] === 'CalculatedPropertyValue') {
        return (
            <DataFieldString
                containerRef={containerRef}
                editMode={editMode}
                setEditMode={setEditMode}
                
                label={label}
                data={(data as CalculatedPropertyValue).value ?? ""}

                isDataLoading={isDataLoading}
            />
        );
    }

    // ObjectPropertyValue
    if (typeof data === 'object' && data['discriminator'] === 'ObjectPropertyValue') {
        return (
            <DataFieldObject
                containerRef={containerRef}
                setEditMode={setEditMode}
                
                label={label}
                data={data as ObjectPropertyValue}

                isDataLoading={isDataLoading}

                onEdit={onEdit}
                isExpandable={isExpandable}
            />
        );
    }

    // TablePropertyValue
    if (typeof data === 'object' && data['discriminator'] === 'TablePropertyValue') {
        return (
            <DataFieldTable
                containerRef={containerRef}
                setEditMode={setEditMode}
                
                label={label}
                data={data as TablePropertyValue}

                isDataLoading={isDataLoading}

                onEdit={onEdit}
                isExpandable={isExpandable}
            />
        );
    }

    return <div>Unsupported Data Type</div>;
}
