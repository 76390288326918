import { Divider } from "@mui/material";
import { CalculatedPropertyValue, ObjectPropertyValue, ObjectValueInformation, SimplePropertyValue, TablePropertyValue } from "../../../services/composer";
import { DataField } from "../dataField";
import { DataFieldLayout } from "./dataField.layout";

type DataFieldObjectProps = {
    containerRef: React.RefObject<HTMLDivElement>;
    setEditMode: (value: boolean) => void;

    label?: string;
    data: ObjectPropertyValue;
    
    isDataLoading?: boolean;
    
    onEdit?: (newValue: ObjectPropertyValue) => void;
    isExpandable?: boolean;
};

export const DataFieldObject = ({ containerRef, setEditMode, label, data, isDataLoading, onEdit, isExpandable }: DataFieldObjectProps) => {
    const handleEdit = (index: number, newValue: string | number | ObjectPropertyValue | TablePropertyValue) => {
        const updatedData = { ...data };
        updatedData.values = [...data.values!];

        if(typeof newValue === 'number') return;
        if (typeof newValue === 'string') {
            updatedData.values[index] = {
                ...updatedData.values[index],
                value: newValue as SimplePropertyValue | CalculatedPropertyValue,
            };
        } else {
            updatedData.values[index] = {
                ...updatedData.values[index],
                value: newValue,
            };
        }

        onEdit?.(updatedData);
    };
    
    return (
        <DataFieldLayout
                content={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {data.values?.map((objPropValue: ObjectValueInformation, idx: number) => (
                            <div key={idx}>
                                <DataField
                                    label={objPropValue.name ?? ''}
                                    data={objPropValue.value ?? ''}
                                    onEdit={onEdit === undefined ? (newValue) => handleEdit(idx, newValue) : undefined}
                                    isExpandable={isExpandable}
                                />
                                { idx !== data.values!.length - 1 && <Divider className="my-1" sx={{ backgroundColor: 'black' }} /> }
                            </div>
                        ))}
                    </div>
                }
                containerRef={containerRef}
                label={label}
                setEditMode={setEditMode}
                isDataLoading={isDataLoading}
                isExpandable={isExpandable}
            />
    );
}