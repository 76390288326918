/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PropertySetBaseDto } from '../models/PropertySetBaseDto';
import type { PropertySetDto } from '../models/PropertySetDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PropertySetsService {

    /**
     * Endpoint to create a new propertySet.
     * @param name Name of the property set.
     * @param description Description of the property set.
     * @returns PropertySetDto Created
     * @throws ApiError
     */
    public static createPropertySet(
name?: string,
description?: string,
): CancelablePromise<PropertySetDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/propertysets',
            query: {
                'Name': name,
                'Description': description,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Endpoint to get every propertySet.
     * @returns PropertySetDto OK
     * @throws ApiError
     */
    public static getPropertySets(): CancelablePromise<Array<PropertySetDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/propertysets',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Endpoint to update a propertySet.
     * @param propertySetId Id the propertySet to be updated.
     * @param requestBody Data the propertySet will be updated with.
     * @returns PropertySetDto Created
     * @throws ApiError
     */
    public static updatePropertySet(
propertySetId: string,
requestBody?: PropertySetBaseDto,
): CancelablePromise<PropertySetDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/propertysets/{propertySetId}',
            path: {
                'propertySetId': propertySetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Endpoint to delete a propertySet.
     * @param propertySetId Id the propertySet to be deleted.
     * @returns PropertySetDto Created
     * @throws ApiError
     */
    public static deletePropertySet(
propertySetId: string,
): CancelablePromise<PropertySetDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/propertysets/{propertySetId}',
            path: {
                'propertySetId': propertySetId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Endpoint to update the properties of a propertySet.
     * @param propertySetId Id the propertySet to be updated.
     * @param propertyIds Ids of the propertySets assigned to the propertySet
     * @returns PropertySetDto Created
     * @throws ApiError
     */
    public static updatePropertySetProperties(
propertySetId: string,
propertyIds?: Array<string>,
): CancelablePromise<PropertySetDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/propertysets/{propertySetId}/properties',
            path: {
                'propertySetId': propertySetId,
            },
            query: {
                'propertyIds': propertyIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Endpoint to get a property with a given id.
     * @param id Id to search for a property.
     * @returns PropertySetDto OK
     * @throws ApiError
     */
    public static getPropertySet(
id: string,
): CancelablePromise<PropertySetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/propertysets/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
