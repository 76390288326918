import { useCallback, useMemo } from "react";
import { CalculatedPropertyValue, ObjectPropertyValue, PropertyCollectionDto, PropertyEntryDto, SimplePropertyValue, TablePropertyValue } from "../../../services/composer";

type UsePropertyCollectionProps = {
    data?: PropertyCollectionDto;
    onEdit?: (updatedData: PropertyCollectionDto) => void;
};

const usePropertyCollection = ({ data, onEdit }: UsePropertyCollectionProps) => {
    // Group property entries by PropertySet IDs
    const propertySetsMap = useMemo(() => {
        const map: { [key: string]: PropertyEntryDto[] } = {};
        data?.propertyEntries?.forEach((entry) => {
            const propertyId = entry.property?.id || null;
            const relevantSets = data.propertySets?.filter(set => set.propertyIds?.includes(propertyId || '')) || [];
    
            relevantSets.forEach(set => {
                const setId = set.id;
                if (setId) {
                    if (!map[setId]) {
                        map[setId] = [];
                    }
                    map[setId].push(entry);
                }
            });
        });
        return map;
    }, [data]);

    const handleEdit = useCallback(
        (entry: PropertyEntryDto, newValue: string | number | CalculatedPropertyValue | ObjectPropertyValue | SimplePropertyValue | TablePropertyValue) => {
            if (!data) return;
    
            // Create a deep copy of localData
            const updatedData: PropertyCollectionDto = {
                ...data,
                propertyEntries: data.propertyEntries ? [...data.propertyEntries] : [],
            };

            // Find the index of the entry to be updated
            const entryIndex = updatedData.propertyEntries?.findIndex(
                (e) => e.property?.id === entry.property?.id
            );
    
            if (entryIndex === undefined) return;

            if (entryIndex >= 0 && updatedData.propertyEntries) {
                if (typeof newValue === "number") return;
                if (typeof newValue === "string") return;
                // Update the value
                updatedData.propertyEntries[entryIndex] = {
                    ...updatedData.propertyEntries[entryIndex],
                    value: newValue,
                };

                onEdit?.(updatedData);
            }
        },
        [data, onEdit]
    );

    const ungroupedEntries = useMemo(() => {
        return data?.propertyEntries?.filter(entry => {
            const propertyId = entry.property?.id || null;
            return !data.propertySets?.some(set => set.propertyIds?.includes(propertyId || ''));
        }) || [];
    }, [data]);

    return {
        propertySetsMap,
        handleEdit,
        ungroupedEntries,
    };
};

export default usePropertyCollection;
