import {
	MaterialReactTable,
	MRT_Cell,
	MRT_Row,
	MRT_TableInstance,
	useMaterialReactTable,
	type MRT_ColumnDef,
	type MRT_RowData,
	type MRT_TableOptions,
} from 'material-react-table';
import { ReactNode } from 'react';

interface Props<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
	columns: MRT_ColumnDef<TData>[];
	data: TData[];
	actionButtons: (props: {
		cell: MRT_Cell<TData, unknown>;
		row: MRT_Row<TData>;
		staticRowIndex?: number | undefined;
		table: MRT_TableInstance<TData>;
	}) => ReactNode;
}

export const DataTable = <TData extends MRT_RowData>({ columns, data, actionButtons, ...props }: Props<TData>) => {
	const table = useMaterialReactTable({
		columns,
		data,
		...props,
		enableStickyFooter: true,
		enableColumnOrdering: true,
		enableGlobalFilterModes: false,
		enableDensityToggle: false,
		enableStickyHeader: true,
		enablePagination: false,
		getRowId: (originalRow) => originalRow.objectId,
		enableRowActions: actionButtons !== undefined,
		renderRowActions: (props) => <div className='flex gap-1'>{actionButtons(props)}</div>,
		positionActionsColumn: 'last',
		enableGrouping: true,
		initialState: {
			sorting: [
				{
					id: 'name',
					desc: false
				}
			],
			density: 'comfortable',
		},
		muiTableContainerProps: { sx: { height: 'calc(100% - 6.5rem)', borderRadius: 0 } },
		muiTablePaperProps: { sx: { height: '100%', borderRadius: 0 } },
		enableBottomToolbar: true,
		layoutMode: 'grid',
	});

	return <MaterialReactTable table={table} />;
};
