// REACT
import { useDispatch } from 'react-redux';
import { setActionMode } from '../../../context/appReducer';
import { setActiveComposition } from '../../../context/compositionsReducer';
import { useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
// COMPONENTS
import { Text } from '@lbc-toolkit/text';
import { Modal } from 'react-bootstrap';
import DetailsGeneral from './DetailsGeneral';
// STYLING
import './DetailsModal.scss';
// MODELS
import { ActionModeType } from '../../../models/models';
// TRANSLATIONS
import { useTranslate } from '@tolgee/react';
// ICONS
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DetailsModal = () => {
	const { t } = useTranslate();
	const dispatch = useDispatch();
	const actionMode = useAppSelector((state: RootState) => state.app.actionMode);

	/**
	 * Closes model dialog
	 */
	const onClose = () => {
		dispatch(setActiveComposition(null));
		dispatch(setActionMode(ActionModeType.None));
	};

	return (
		<Modal dialogClassName='details-modal-layout' centered show={actionMode === ActionModeType.Details}>
			<Modal.Header>
				<Modal.Title>
					<Text type='h2'>{t('compDetails')}</Text>
					<FontAwesomeIcon className='px-1 clickable modal-button-close' icon={faClose} onClick={onClose} />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{
				padding: '16px',
				margin: '0',
				backgroundColor: '#eff3f6',
				height: '75vh',
				display: 'flex',
				flexDirection: 'column',
			}}>
				<DetailsGeneral />
			</Modal.Body>
		</Modal>
	);
};

export default DetailsModal;
