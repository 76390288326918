import { Spinner } from "@lbc-toolkit/spinner";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, styled, Typography } from "@mui/material";
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { useState } from "react";

type DataFieldLayoutProps = {
    content: React.ReactNode,
    isDataLoading?: boolean,
    containerRef: React.RefObject<HTMLDivElement>,
    label?: string,
    enableHovering?: boolean,
    setEditMode?: (value: boolean) => void,
    isExpandable?: boolean,
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(() => ({
    marginLeft: 'auto',
    transition: 'transform 0.3s',
    variants: [
      {
        props: ({ expand }) => !expand,
        style: {
          transform: 'rotate(0deg)',
        },
      },
      {
        props: ({ expand }) => !!expand,
        style: {
          transform: 'rotate(180deg)',
        },
      },
    ],
}));

export const DataFieldLayout : React.FC<DataFieldLayoutProps> = ({
    content,
    containerRef,
    label,
    setEditMode,
    isDataLoading,
    enableHovering = false,
    isExpandable = false 
  }): JSX.Element => {
    const [expanded, setExpanded] = useState(true);

    return (
        <div ref={containerRef} className="row" style={{ margin: 0 }}>
            {label ?
                <div className="col col-2" style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                        {label}
                        {isExpandable ?
                            <ExpandMore
                                expand={expanded}
                                onClick={() => setExpanded(!expanded)}
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                            :
                            null
                        }
                    </Typography>
                </div>
                :
                null
            }

            <div 
                className= {`col col-10 ${enableHovering ? 'data-field-row' : ''}`}
                style={{ alignContent: 'center', minHeight: '40px', padding: 0 }}
                onClick={() => { setEditMode?.(true) }}
            >
                { isDataLoading ?
                    <Spinner spin={true} />
                    :
                    isExpandable ? 
                        <Collapse in={expanded} timeout='auto'>
                            {content}
                        </Collapse>
                        :
                        content
                }
            </div>
        </div>
    );
}