/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompositionElementsDto } from '../models/CompositionElementsDto';
import type { CompositionSimpleDto } from '../models/CompositionSimpleDto';
import type { CompositionVariantSimpleDto } from '../models/CompositionVariantSimpleDto';
import type { CreateCompositionDto } from '../models/CreateCompositionDto';
import type { ElementInstanceDto } from '../models/ElementInstanceDto';
import type { PropertyCollectionDto } from '../models/PropertyCollectionDto';
import type { UpdateCompositionDto } from '../models/UpdateCompositionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompositionsService {

    /**
     * Creates new composition or composition variant.
     * @param requestBody Create composition body
     * @returns any Created
     * @throws ApiError
     */
    public static createComposition(
requestBody?: CreateCompositionDto,
): CancelablePromise<(CompositionSimpleDto | CompositionVariantSimpleDto)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compositions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets all compositions or composition variants without filter
     * @returns any Success
     * @throws ApiError
     */
    public static getCompositions(): CancelablePromise<Array<(CompositionSimpleDto | CompositionVariantSimpleDto)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compositions',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets the properties of a composition or composition variant.
     * @param compositionId 
     * @returns PropertyCollectionDto Success
     * @throws ApiError
     */
    public static getCompositionProperties(
compositionId: string,
): CancelablePromise<PropertyCollectionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compositions/{compositionId}/properties',
            path: {
                'compositionId': compositionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Updates an existing composition or composition variant.
     * @param compositionId 
     * @param requestBody Update composition input
     * @returns void 
     * @throws ApiError
     */
    public static updateComposition(
compositionId: string,
requestBody?: UpdateCompositionDto,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/compositions/{compositionId}',
            path: {
                'compositionId': compositionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes an existing composition or composition variant.
     * @param compositionId Delete composition input
     * @returns void 
     * @throws ApiError
     */
    public static deleteComposition(
compositionId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/compositions/{compositionId}',
            path: {
                'compositionId': compositionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets the composition details given a composition id.
     * @param compositionId 
     * @returns CompositionElementsDto Success
     * @throws ApiError
     */
    public static getCompositionElements(
compositionId: string,
): CancelablePromise<CompositionElementsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compositions/{compositionId}/compositionelements',
            path: {
                'compositionId': compositionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update composition elements.
     * @param compositionId 
     * @param requestBody 
     * @returns CompositionElementsDto Success
     * @throws ApiError
     */
    public static updateCompositionElements(
compositionId: string,
requestBody?: Array<ElementInstanceDto>,
): CancelablePromise<CompositionElementsDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/compositions/{compositionId}/elements',
            path: {
                'compositionId': compositionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Creates a new instance for the given composition id.
     * @param id The id of the composition for which a new instance should be created.
     * @returns ElementInstanceDto Success
     * @throws ApiError
     */
    public static getCompositionsInstancefor(
id: string,
): CancelablePromise<ElementInstanceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compositions/instancefor/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get the composition that use the given composition.
     * @param compositionId The composition to find the dependencies.
     * @returns string Success
     * @throws ApiError
     */
    public static getDependencies(
compositionId: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compositions/{compositionId}/dependencies',
            path: {
                'compositionId': compositionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
