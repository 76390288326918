import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { setCompositionElements } from '../../../../context/editorReducer';
import { useAppDispatch } from '../../../../context/hooks';
import { CompositionsService } from '../../../../services/composer';
import useEditor from '../../hooks/useEditor';
import './VariantEditor.scss';
import CombinationsList from './features/CombinationsList';
import ElementsList from './features/ElementsList';

const VariantEditor = () => {
	const dispatch = useAppDispatch();
	const { currentComposition } = useEditor();

	const {
		data: compositionElements,
		isPending,
		isSuccess,
	} = useQuery({
		queryKey: ['compositionElements', currentComposition?.id],
		queryFn: () => CompositionsService.getCompositionElements(currentComposition?.id!),
	});

	useEffect(() => {
		if (compositionElements && isSuccess) {
			dispatch(setCompositionElements(compositionElements));
		}
	}, [compositionElements]);

	return (
		<div className='bg-color-contrast flex flex-grow flex-row'>
			<ElementsList isLoading={isPending} className='flex-1' />
			<CombinationsList isLoading={isPending} className='flex-[3]' />
		</div>
	);
};

export default VariantEditor;
