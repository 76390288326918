// MUI
import { createTheme } from "@mui/material/styles";

export const liebherrTheme = createTheme({
	palette: {
		primary: {
			main: "#ffd000",
		},
		secondary: {
			main: "#524F53",
		},
		error: {
			main: "#f89939",
		},
		warning: {
			main: "#FFAF00",
		},
		success: {
			main: "#19AF37",
		},
		info: {
			main: "#676063",
		},
	},
	components: {
		MuiTypography: {
			variants: [
				{
					props: { variant: 'h1' },
					style: {
						fontSize: '3.5rem',
						lineHeight: '4rem',
						fontWeight: '400',
						letterSpacing: '.009375em',
						fontFamily: 'LiebherrHead-Black',
					},
				},
				{
					props: { variant: 'h2' },
					style: {
						fontSize: '3rem',
						lineHeight: '3.5rem',
						fontWeight: '400',
						letterSpacing: '.0075em',
						fontFamily: 'LiebherrHead-Black',
					},
				},
				{
					props: { variant: 'h3' },
					style: {
						fontSize: '2.5rem',
						lineHeight: '3rem',
						fontWeight: '400',
						letterSpacing: '.005em',
						fontFamily: 'LiebherrHead-Black',
					},
				},
				{
					props: { variant: 'h4' },
					style: {
						fontSize: '2.125rem',
						lineHeight: '2.5rem',
						fontWeight: '400',
						letterSpacing: '.00375em',
						fontFamily: 'LiebherrHead-Black',
					},
				},
				{
					props: { variant: 'h5' },
					style: {
						fontSize: '1.5rem',
						lineHeight: '2rem',
						fontWeight: '400',
						fontFamily: 'LiebherrHead-Black',
					},
				},
				{
					props: { variant: 'h6' },
					style: {
						fontSize: '1.00rem',
						lineHeight: '2rem',
						fontWeight: '500',
						letterSpacing: '.0125em',
						fontFamily: 'LiebherrHead-Black',
					},
				},
			],
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&:hover': {
						color: '#eb6f24',
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: '#FFFFFF',
					color: '#000000',
					borderRadius: '0px',
					boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
					fontSize: '0.9rem',
				},
				arrow: {
					color: '#FFFFFF',
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				thumb: {
					color: '#000000',
					'&:hover': {
						color: '#FFD000',
					},
					'&.Mui-active': {
						color: '#eb6f24',
					},
				},
				track: {
					color: '#000000',
				},
				rail: {
					color: '#000000',
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						color: 'black', // Text color
						height: '40px', // Height of the input field
						padding: '0 10px', // Vertical padding 00px, horizontal padding 20px

						'& fieldset': {
							borderRadius: 0, // Squared border
						},

						'&.Mui-focused fieldset': {
							borderColor: 'black', // Border color on focus
						},

						'& input': {
							padding: '0 10px', // Adjust inner padding specifically if needed
						},
					},
				},
			},
		},
	},
});