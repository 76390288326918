
import { Button } from '@lbc-toolkit/button';
import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { setToast } from '../../../context/appReducer';
import { setActiveComposition, setCompositionsListNeedsReload } from '../../../context/compositionsReducer';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import DataField from '../../../features/dataField';
import PropertyCollection from '../../../features/propertyCollection';
import { ActionModeType, Severity } from '../../../models/models';
import { ClassificationsService } from '../../../services/catalog';
import { CompositionSimpleDto, CompositionsService, PropertyCollectionDto, UpdateCompositionDto } from '../../../services/composer';
import { Status } from '../../../services/warehouse';
import { queryClient } from '../../App';
import './DetailsModal.scss';

const DetailsGeneral = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslate();
	const activeComposition = useAppSelector((state: RootState) => state.compositions.activeComposition);
	const [actionMode, setActionMode] = useState<ActionModeType>(ActionModeType.Details);
	const [editObject, setEditObject] = useState<CompositionSimpleDto | null>(activeComposition);
	const [hasChanged, setHasChanged] = useState(false);
	const [propertyCollection, setPropertyCollection] = useState<PropertyCollectionDto | null>(null);

	const { data: classification, isLoading: isClassificationQueryLoading } = useQuery({
		queryKey: ['classification', editObject?.classificationId],
		queryFn: () => {
			return ClassificationsService.getClassification(editObject?.classificationId!);
		},
	});

	useEffect(() => {
		CompositionsService.getCompositionProperties(activeComposition?.id!)
			.then((response: PropertyCollectionDto) => {
				setPropertyCollection(response);
			})
			.catch((error: any) => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('couldNotLoadProperties'),
					}),
				);
			});
	}, []);


	useEffect(() => {
		if (actionMode === ActionModeType.Edit) {
			setEditObject(activeComposition);
		}
	}, [actionMode]);

	/**
	 * Saves changes on editor for general attributes
	 */
	const saveComposition = () => {
		dispatch(setActiveComposition(editObject));

		const updateComposition: UpdateCompositionDto = {
			name: editObject?.name!,
			description: editObject?.description!,
			articleNumber: editObject?.articleNumber,
			status: editObject?.status!,
			propertyCollection: propertyCollection!,
		};		

		CompositionsService.updateComposition(editObject?.id!, updateComposition)
			.then(() => {
				dispatch(setCompositionsListNeedsReload(true));
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('compositionUpdated'),
					}),
				);
				queryClient.invalidateQueries({ queryKey: ['compositions'] });
				setHasChanged(false);
			})
			.catch((error: any) => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('couldNotSaved'),
					}),
				);
			});

		setActionMode(ActionModeType.Details);
	};

	return (
		<div style={{
			height: '100%',
			backgroundColor: 'white', 
			display: 'flex',
			flexDirection: 'column',
			gap: '8px',
			padding: '16px',
			overflowX: 'hidden', 
			overflowY: 'scroll', 
		}}>
			<Typography variant='h5'>{t('generalParameters')}</Typography>

			<DataField
				label={t('name')}
				data={editObject?.name ?? ""}
				onEdit={(value) => {
					setEditObject({ ...editObject, name: value as string })
					setHasChanged(true);
				}}
			/>

			<DataField
				label={t('articleNumber')}
				data={editObject?.articleNumber ?? ""}
				onEdit={(value) => {
					setEditObject({ ...editObject, articleNumber: value as string })
					setHasChanged(true);
				}}
			/>

			<DataField
				label={t('classification')}
				data={classification?.name ?? ""}
				isDataLoading={isClassificationQueryLoading}
			/>

			<DataField
				label={t('description')}
				data={editObject?.description ?? ""}
				onEdit={(value) => {
					setEditObject({ ...editObject, description: value as string })
					setHasChanged(true);
				}}
			/>

			<DataField
				label={t('status')}
				data={editObject?.status ?? ""}
				dropdownOptions={Object.values(Status)}
				onEdit={(selectedOption) => {
					setEditObject((prev) => ({ ...prev, status: Status[selectedOption as keyof typeof Status] }))
					setHasChanged(true);
				}}
			/>

			<hr />

			<PropertyCollection
				data={propertyCollection ?? undefined}
				onEdit={(updatedData: PropertyCollectionDto) => {
					setPropertyCollection(updatedData);
					setHasChanged(true);
				}
			} />

			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
				<Button disabled={!hasChanged} label={t('Save')} onClick={() => { 
					saveComposition()
				}}
				/>
			</div>

		</div>
	);
};

export default DetailsGeneral;
