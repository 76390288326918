import { TextField } from "@mui/material";
import { DataFieldLayout } from "./dataField.layout";

type DataFieldNumberProps = {
    containerRef: React.RefObject<HTMLDivElement>;
    editMode: boolean;
    setEditMode: (value: boolean) => void;

    label?: string;
    data: number;
    
    isDataLoading?: boolean;
    
    onEdit?: (newValue: number) => void;
    isDecimal?: boolean;
};

export const DataFieldNumber = ({ containerRef, editMode, setEditMode, label, data, isDataLoading, onEdit, isDecimal }: DataFieldNumberProps) => {    
    return (
        <DataFieldLayout
            content={onEdit && editMode ?
                <TextField
                    type="number"
                    value={data}
                    onChange={(e) => {
                        const value = isDecimal ? parseFloat(e.target.value) : parseInt(e.target.value, 10);
                        onEdit(value);
                    }}
                />
                :
                <div className="px-2">{data ?? "No value"}</div>
            } 
            containerRef={containerRef}
            label={label}
            setEditMode={setEditMode}
            isDataLoading={isDataLoading}
            enableHovering={onEdit !== undefined}
        />
    );
}