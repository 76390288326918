import { TextInput } from "@lbc-toolkit/textinput";
import { DataFieldLayout } from "./dataField.layout";

type DataFieldStringProps = {
    containerRef: React.RefObject<HTMLDivElement>;
    editMode: boolean;
    setEditMode: (value: boolean) => void;

    label?: string;
    data: string;
    
    isDataLoading?: boolean;
    
    onEdit?: (newValue: string) => void;
};

export const DataFieldString = ({ containerRef, editMode, setEditMode, label, data, isDataLoading, onEdit }: DataFieldStringProps) => {
    return (
        <DataFieldLayout
            content={onEdit && editMode ?
                <TextInput
                    type="text"
                    value={data}
                    onChange={onEdit ? (value: string) => onEdit(value) : undefined}
                />
                :
                <div className="px-2">{data ?? "No value"}</div>
            } 
            containerRef={containerRef}
            label={label}
            setEditMode={setEditMode}
            isDataLoading={isDataLoading}
            enableHovering={onEdit !== undefined}
        />
    );
}